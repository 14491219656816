import React, {useEffect, useState} from 'react';
import {DefaultLayout, HomepageBanner, HomepageProjects, HomepageExperiences, HomepageSkills} from '../components';
import NotFound from './NotFound';
import {client} from '../utils/prismicHelpers';
import Prismic from '@prismicio/client';

/**
 * Website homepage component
 */
const HomePage = () => {
  const [prismicData, setPrismicData] = useState({optionsDoc: null, menuDoc: null, projects: null});
  const [notFound, toggleNotFound] = useState(false);

  // Get the homepage document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const optionsDoc = await client.getSingle('options');
        const menuDoc = await client.getSingle('menu');
        const projects = await client.query(
            Prismic.Predicates.at('document.type', 'project'),
            // {orderings: 'my.project.date desc'}
        );

        if (optionsDoc) {
          setPrismicData({optionsDoc, menuDoc, projects});
        } else {
          console.warn('Options document was not found. Make sure it exists in your Prismic repository.');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    };

    fetchPrismicData();
  }, []);

  // Return the page if a document was retrieved from Prismic
  if (prismicData.optionsDoc) {
    const optionsDoc = prismicData.optionsDoc;
    const menuDoc = prismicData.menuDoc;
    const projects = prismicData.projects;

    return (
        <DefaultLayout
            wrapperClass="homepage"
            menuDoc={menuDoc}
        >
          <img className="shape-1" id="shape-1" src="images/shape-teal.svg" alt="élément décoratif"/>
          <img className="shape-2" src="images/shape-indigo.svg" alt="élément décoratif"/>
          <img className="shape-3" src="images/shape-light.svg" alt="élément décoratif"/>
          <HomepageBanner tagline={optionsDoc.data.homepage_tagline}/>
          <HomepageProjects projects={projects.results} tagline={optionsDoc.data.homepage_projects_tagline}/>
          <HomepageSkills data={optionsDoc.data}/>
          <HomepageExperiences data={optionsDoc.data}/>
        </DefaultLayout>
    );
  } else if (notFound) {
    return <NotFound/>;
  }
  return null;
};

export default HomePage;
