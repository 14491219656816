import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {MenuLink} from '/';

/**
 * Site header/nav component
 */
const Header = ({menuDoc}) => {
  // if (menuDoc) {
    return (
        <header className="site-header">
          <RouterLink to="/">
            <div className="logo"><img src="/images/logo-marandon-dark.svg" alt="logo"/>
            </div>
          </RouterLink>
          <nav className="d-none d-lg-inline-block">
            <ul className="nav">
              {/*{menuDoc.data.menu_links.map(menuLink => (*/}
              {/*    <MenuLink*/}
              {/*        menuLink={menuLink}*/}
              {/*        key={menuLink.link.id}*/}
              {/*    />*/}
              {/*))}*/}
              <li className="nav-item">
                <a className="nav-link contact" href="/contact/">Contact</a>
              </li>
            </ul>
          </nav>
        </header>
    );
  // }
  // return null;
};

export default Header;