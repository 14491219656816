import React, {useEffect, useState} from 'react';
import {DefaultLayout, SliceZone, SocialNetworks} from '../components';
import NotFound from './NotFound';
import {client} from '../utils/prismicHelpers';
import {RichTextField} from '../components/prismic-elements';
import Prismic from '@prismicio/client';
import HomepageSingleProject from '../components/HomepageSingleProject';

/**
 * Website page component
 */
const ProjectList = ({match}) => {
  const [prismicData, setPrismicData] = useState({projectsList: null, menuDoc: null});
  const [notFound, toggleNotFound] = useState(false);
  // Get the page document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const projectsList = await client.query(
            Prismic.Predicates.at('document.type', 'project'),
            // {orderings: 'my.project.date desc'}
        );
        const menuDoc = await client.getSingle('menu');

        if (projectsList) {
          setPrismicData({projectsList, menuDoc});
        } else {
          console.warn('Project was not found. Make sure it exists in your Prismic repository');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    };
    fetchPrismicData();

    // Load new page at the top (when linking from the middle of another page)
    window.scrollTo(0, 0);
  }, []);

  // Return the page if a document was retrieved from Prismic
  if (prismicData.projectsList) {
    const projectsList = prismicData.projectsList;
    const menuDoc = prismicData.menuDoc;
    let rowNumber = 0;
    console.log(projectsList);
    projectsList.results = projectsList.results.concat(projectsList.results);

    return (
        <DefaultLayout
            wrapperClass="page projects-list"
            menuDoc={menuDoc}
        >
          <div className="content-container container">
            <div className="row">
              <div className="col-12">
                <h1 className="h2">Mes derniers projets</h1>
              </div>
              {projectsList.results.map((project, index) => {
                rowNumber = (index % 2 === 0) ? rowNumber + 1 : rowNumber;
                return (
                    <div className={'col-md-' + ((rowNumber % 2 === 0 && index % 2 === 0) || (rowNumber % 2 !== 0 && index % 2 !== 0) ? 5 : 7)} key={project.id}>
                      <div className="project-preview">
                        <a href={'/projets/' + project.uid}>
                          <img src={project.data.thumb.url} alt={project.data.thumb.alt}/>
                          <h5 className="h5">{project.data.title[0].text}</h5>
                        </a>
                      </div>
                    </div>);
              })}

            </div>
          </div>
        </DefaultLayout>
    );
  } else if (notFound) {
    return <NotFound/>;
  }
  return null;
};

export default ProjectList;
