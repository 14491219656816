import React, {useEffect, useState} from 'react';
import {DefaultLayout} from '../components';
import NotFound from './NotFound';
import {client} from '../utils/prismicHelpers';

/**
 * Website page component
 */
const ContactForm = () => {
  const [prismicData, setPrismicData] = useState({menuDoc: null});
  const [notFound, toggleNotFound] = useState(false);

  const [formValues, setFormValues] = useState({full_name: null, mail: null, phone: null, message: null});
  const [ajaxResponse, setAjaxResponse] = useState(false);
  const [loading, setLoading] = useState(false);

  // Get the page document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        // const menuDoc = await client.getSingle('menu');
        const menuDoc = null;

        // if (menuDoc) {
          setPrismicData({menuDoc});
        // } else {
        //   console.warn('Error fetching prismic');
        //   toggleNotFound(true);
        // }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    };
    fetchPrismicData();
    window.scrollTo(0, 0);

  }, []);

  // Form Submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Set loader
    setLoading(true);
    const response = await fetch(window.location.href, {
      method: 'POST',
      cache: 'no-cache',
      body: new FormData(document.getElementById('contact-form')),
    });

    if (response.ok) {
      response.json().then(data => {
        // Hide loader
        setLoading(false);

        // Set ajax response (will refresh state)
        setAjaxResponse(data);
        window.scrollTo(0, 0);
      });
    } else {
      setLoading(false);
      setAjaxResponse({success: false, message: 'L\'envoie du formulaire n\'a pas pu être effectué'});
      window.scrollTo(0, 0);
    }

  };

  // Form change
  const handleChange = (event) => {
    formValues.[event.target.name] = event.target.value;
    setFormValues(formValues);
  };
  const handleKeyPress = (event) => {
    formValues.[event.target.name] = event.target.value;
    setFormValues(formValues);
  };

  // Return the page if a document was retrieved from Prismic
  // if (prismicData.menuDoc) {
    const menuDoc = prismicData.menuDoc;

    return (
        <DefaultLayout wrapperClass="page-contact" menuDoc={menuDoc}>
          <div className="content-container container">
            <div className="row">
              <div className="col-12">
                <h1 className="h2">Contact</h1>
                {ajaxResponse !== false && ajaxResponse.success === true ? <div className="alert alert-success">{ajaxResponse.message}</div> : null}
                {ajaxResponse !== false && ajaxResponse.success === false ? <div className="alert alert-danger">{ajaxResponse.message}</div> : null}
                <form id="contact-form" method="post" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label htmlFor="full_name">Nom complet</label>
                      <input required type="text" id="full_name" name="full_name" className="form-control" onChange={handleChange} onKeyPress={handleKeyPress} value={formValues.full_name}/>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="mail">E-mail</label>
                      <input required type="email" id="mail" name="mail" className="form-control" onChange={handleChange} onKeyPress={handleKeyPress} value={formValues.mail}/>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="phone">Téléphone</label>
                      <input required type="phone" id="phone" name="phone" className="form-control" onChange={handleChange} onKeyPress={handleKeyPress} value={formValues.phone}/>
                    </div>
                    <div className="form-group col-12">
                      <label htmlFor="message">Message</label>
                      <textarea id="message" name="message" className="form-control" rows="5" onChange={handleChange} onKeyPress={handleKeyPress}>{formValues.message}</textarea>
                    </div>
                    <button type="submit" className={'btn btn-primary ld-ext-left' + (loading ? ' running' : '')}>{loading ? <div className="ld ld-ring ld-spin"></div> : <img className="btn-picto" src="/images/send.svg" alt="picto enveloppe"></img>}Envoyer</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </DefaultLayout>
    );
  // } else if (notFound) {
  //   return <NotFound/>;
  // }
  // return null;
};

export default ContactForm;
